import React from 'react';
import {  Box } from '@mui/material';
import load from './loading.gif'
const Loading = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor='grey'
    >
     <img
        src={load}
        alt="Loading GIF"
        style={{ width: '150px', height: '150px' }}
      />
    </Box>
  );
};

export default Loading;