import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoutes from "./components/routes/PrivateRoutes";
import Profile from "./components/Profile/profile";
import LoginSignupRoutes from "./components/routes/LoginSignupRoutes";

import Login from "./components/Login/Login";
// import Page1 from "./components/Pages/Page1";
import SideNav from "./components/Home/SideNav";
import SignUp from "./components/Signup/SignUp";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AllGames from "./components/Extensions/AllGames";

import Loading from "./components/Home/Loading";
import Signup from "./components/Signup/SignUp";
import AddGame from "./components/Extensions/AddGames/AddGame";
import AddCategory from "./components/AddCategory/AddCategory";
import DeleteCategory from "./components/DeleteCategory/DeleteCategory";
import FeaturedGames from "./components/Extensions/FeaturedGames";
import UpdateFeaturedGames from "./components/Extensions/UpdateFeaturedGames";
import AllDetails from "./components/SeoAdd/AllDetails";
import UpdateDetails from "./components/SeoAdd/UpdateDetails";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, login } from "./redux/Slices/authSlice";
import { getCookie } from "./utils/cookiehelper";
export default function App() {
  const dispatch = useDispatch();

  const userState = useSelector((state) => state.user);

  const authenticated = userState.isAuthenticated;
  const [isLoading, setIsLoading] = useState(true);

  const profileFetcher = useCallback(async () => {
    try {
      let cookie = getCookie("token");
      console.log(cookie, "cookie");
      if (cookie) {
        const data = await dispatch(getProfile(cookie));

        // if (data && data.payload && data.payload.data) {
        //   dispatch(login(data.payload.data));
        // }
      }
    } catch (error) {
      console.error("Error fetching profile:", error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (userState.user === null) {
      profileFetcher().then(() => {
        setIsLoading(false);
      });
    } else {
      setIsLoading(true);
    }
  }, []);

  if (isLoading) {
    console.log("loading...");
    return <Loading />;
  }

  return (
    <>
      <Router>
        {/* <SideNav /> */}
        {authenticated ? <SideNav /> : ""}
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<AllGames />} />
            {/* <Route path="/signup" element={<Signup />} /> */}
            <Route path="/addgame" element={<AddGame />} />
            {/* <Route path="/addgame" element={<AllExtensions />} /> */}
            <Route path="/addcategory" element={<AddCategory />} />
            <Route path="/deletecategory" element={<DeleteCategory />} />
            <Route path="/getfeaturedgames" element={<FeaturedGames />} />
            <Route path="/seo" element={<AllDetails />} />
            <Route path="/seo/:id" element={<UpdateDetails />} />
            <Route
              path="/updatefeaturedgames"
              element={<UpdateFeaturedGames />}
            />
            <Route path="/profile" element={<Profile />} />
          </Route>
          <Route element={<LoginSignupRoutes />}>
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
          </Route>
        </Routes>
        <ToastContainer />
      </Router>
    </>
  );
}

// {
//   <Router>
//     {authenticated ? <SideNav /> : ""}
//     <Routes>
//       {/* <Route element={<PrivateRoutes />}> */}
//       {/* <Route path="/" element={<SideNav />}></Route> */}
//       <Route path="/" element={<Dashboard />} />
//       <Route path="/allgames" element={<AllExtensions />} />
//       <Route path="/recently-added-extension" element={<RecentlyAdded />} />
//       <Route path="/recently-updated-extension" element={<RecentlyUpdated />} />
//       <Route path="/recently-removed-extension" element={<RecentlyRemoved />} />
//       <Route path="/unsubscribedUsers" element={<UnsubscribedUsers />} />
//       <Route path="/profile" element={<Profile />} />
//       <Route path="/blog" element={<Blogindex />} />
//       <Route path="/blog/:id" element={<Blogupdate />} />
//       {/* <Route path="/gallery" element={<Gallery />} /> */}
//       <Route path="/gallery/add-image" element={<AddImage />} />
//       <Route path="/gallery/show-image" element={<ShowImages />} />
//       <Route path="/seo" element={<AllDetails />} />
//       <Route path="/seo/:id" element={<UpdateDetails />} />
//       <Route path="/subscribedUsers" element={<SubscribedUsers />} />
//       <Route path="/allUsers" element={<AllUsers />} />
//       <Route path="/extbycat" element={<ExtensionsByCat />} />
//       <Route path="/faq" element={<DisplayfaqTables />} />
//       <Route path="/alltestimonials" element={<AllTestimonial />} />
//       <Route path="/featured" element={<MostDownloaded />} />
//       <Route path="/manifestExt" element={<ManifestExtension />} />
//       <Route path="/contact-details" element={<Contact />}></Route>

//       {/* <Route path="/createTestimonial" element={<AddTestimonial/>}/> */}
//       {/* </Route> */}
//       <Route element={<LoginSignupRoutes />}>
//         <Route path="/signup" element={<SignUp />} />
//         <Route path="/login" element={<Login />} />
//       </Route>
//       {/* <Route path="/" element={<Login/>}/> */}
//     </Routes>
//     <ToastContainer />
//   </Router>;
// }
