import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddDetails from "./AddDetails";
import DataTable from "./SeoDatatable";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { getSEO } from "../../redux/Slices/extSlice";


export default function AllDetails() {
  const [seoDetails, setSeoDetails] = useState([]);
  const [update, setUpdate] = useState(false);
  const [page, pagechange] = useState(0);
  const [rowsPerPage, rowsPerPageChange] = useState(5);
  const dispatch = useDispatch();
  const { seodata } = useSelector((state) => state.ext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getSEO());
      } catch (error) {
        console.log("Error fetching SEO details:", error);
      }
    };
    fetchData();
  }, [update]);

  console.log(seodata, "seodata");

  // useEffect(() => {
  //   if (getDetails && getDetails.data && getDetails.data.length > 0) {
  //     const formattedSeo = getDetails.data.map((seoo, index) => ({
  //       id: seoo.id,
  //       sl: index + 1,
  //       title: seoo.seotitle,
  //       description: seoo.seodesc,
  //       data: seoo.page,
  //       active: seoo.active,
  //     }));
  //     setSeoDetails(formattedSeo);
  //   } else {
  //     setSeoDetails([]);
  //   }
  // }, [getDetails, update]);

  const transformedData = seodata?.data.map((item) => ({
    ...item,
    id: item._id, // Add an 'id' field based on '_id'
  }));
  console.log(transformedData, "transformedData");

  return (
    <div>
      <AddDetails setUpdate={setUpdate} />
      <DataTable
        rows={transformedData ? transformedData : []}
        setUpdate={setUpdate}
      />

      {/* <Datatable
        title="SEO Details"
        data={seodata.data ? seodata.data : []}
        columns={columns}
        options={options}
      /> */}
    </div>
  );
}
