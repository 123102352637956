import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getallcategory, deletecategory } from "../../redux/Slices/extSlice";
import { Paper, TextField, Button, MenuItem, FormControl } from "@mui/material";
import { toast } from "react-toastify";
const DeleteCategory = () => {
  const catref = useRef();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);

  const { user } = useSelector((state) => state.user);
  const { allcategory } = useSelector((state) => state.ext);

  useEffect(() => {
    dispatch(getallcategory());
  }, [dispatch]);

  useEffect(() => {
    if (allcategory) {
      setCategories(allcategory.data);
    }
  }, [allcategory]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const categoryId = catref.current.value;

    if (!categoryId) {
      console.log("No category selected");
      return;
    }
    if (user?.role == "admin" || user?.user?.role == "admin") {
      dispatch(deletecategory({ id: categoryId }))
        .then((data) => {
          if (data.error) {
            throw new Error(data.error.message || "Error deleting category");
          }

          console.log("Deleted category data:", data);

          // Re-fetch the categories from the server
          dispatch(getallcategory());

          // Reset the select input
          catref.current.value = "";
        })
        .catch((err) => {
          console.log(err, "error message");
        });
    } else {
      toast.error("You are not authorize for this action", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };
  return (
    <Paper
      sx={{
        width: "75%",
        float: "right",
        overflow: "auto",
        marginTop: "8%",
        marginRight: "23px",
        padding: "10px",
      }}
    >
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <TextField
            select
            inputRef={catref}
            label="Select category"
            defaultValue=""
            fullWidth
            variant="outlined"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {categories.map((category) => (
              <MenuItem value={category._id} key={category._id}>
                {category.cat_name}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Delete
        </Button>
      </form>
    </Paper>
  );
};

export default DeleteCategory;
