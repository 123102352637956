// combinedSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  loginWithPassword,
  signup,
  getProfiler,
  logoutsess,
} from "../api/authapi";
import { toast } from "react-toastify";

export const loginAsync = createAsyncThunk(
  "user/login",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await loginWithPassword(credentials);
      if (response.data.code == 200) {
        toast.success("Login Successfull", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        return response.data;
      } else {
        toast.error("Login Failed", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const registerAsync = createAsyncThunk(
  "user/register",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await signup(userData);
      if (response.data.code == 200) {
        toast.success("Account Created Successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        return response.data;
      } else {
        toast.error("Account Creation Failed", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getProfile = createAsyncThunk(
  "user/profile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getProfiler(data);
      if (response.data.code == 200) {
        toast.success("Profile Fetched Successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        return response.data;
      } else {
        toast.error(`${response?.data?.message || "Failed to fetch profile"}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// export const logoutuser = createAsyncThunk("user/logout", async () => {
//   try {
//     const response = await logoutsess();
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// });

const authSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    isAuthenticated: false,
    role: "",
    loading: false,
    successMsg: "",
    error: "",
  },
  reducers: {
    login: (state, action) => {
      console.log(action.payload, "in Login sync");
      state.user = action.payload;
      state.isAuthenticated = true;
      state.role = action.payload.role;
    },

    setAdminRole: (state, action) => {
      state.role = action.payload;
    },
    Logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginAsync.pending, (state) => {
      state.loading = true;
      state.isAuthenticated = false;
    });
    builder.addCase(loginAsync.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.loading = false;
      // state.user = action.payload;
      // console.log(state.isAuthenticated);
    });
    builder.addCase(loginAsync.rejected, (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.loading = false;
    });

    builder.addCase(registerAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(registerAsync.fulfilled, (state, action) => {
      state.isAuthenticated = false;
      state.loading = false;
    });
    builder.addCase(registerAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getProfile.fulfilled, (state, action) => {
      console.log(action.payload, "action.payload");
      state.user = action.payload;
      state.isAuthenticated = true;
    });
    builder.addCase(getProfile.rejected, (state) => {
      state.isAuthenticated = false;
      state.user = null;
    });
  },
});

export const { login, Logout, setAdminRole } = authSlice.actions;
export default authSlice.reducer;
