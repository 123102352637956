export function setCookie(
  cookieName,
  cookieValue,
  expiryDays = 365,
  sameSite = "Strict"
) {
  var d = new Date();
  d.setTime(d.getTime() + expiryDays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  var sameSiteValue = sameSite ? "; SameSite=" + sameSite : "";
  document.cookie =
    cookieName + "=" + cookieValue + ";" + expires + ";path=/" + sameSiteValue;
}
export function getCookie(cookieName) {
  var name = cookieName + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var cookieArray = decodedCookie.split(";");
  for (var i = 0; i < cookieArray.length; i++) {
    var cookie = cookieArray[i];
    while (cookie.charAt(0) == " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) == 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
}

export function deleteCookie(name) {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
}