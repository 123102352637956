import axios from "axios";
import AppConstatnt from "../../constants/AppConstant";
// const baseURL = "http://localhost:8800/api/";

const API = axios.create({ baseURL: `${AppConstatnt.baseURL}` });

export const getAllGames = (data) => API.post("/games/allgames", data);
export const getAllCategories = () => API.get("/category/getallcategories");
export const updategamedetail = (formdata) =>
  API.put("/games/updategametails", formdata);
export const updategamedetailformdata = (formdata) =>
  API.post("/games/updategametailsform", formdata, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
export const deletegamedetail = (formdata) =>
  API.delete("/games/deletegamedetail", { data: formdata });
export const deletefilepath = (data) => API.put("/games/deletepath", { data });
export const addcategory = (formdata) => {
  return API.post("/category/addcategory", formdata);
};
export const getfeaturedGames = () => {
  return API.get("/featured/featuredgames");
};

export const setfeaturedpriority = (formdata) => {
  return API.post("/featured/setpriority", formdata);
};
export const addgame = (formdata) =>
  API.post("/games/addgames", formdata, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
export const getAllCategory = () => API.get("/category/getallcategories");

export const deleteCategory = (formdata) => {
  return API.delete("/category/deletecategory", { data: formdata });
};

export const Seoadd = (formdata) => {
  return API.post("/seo/addseo", formdata);
};
export const Seoget = (formdata) => {
  return API.post("/seo/getseo", formdata);
};
export const Seoupdate = (formdata) => {
  return API.post("/seo/updateseo", formdata);
};
export const Seodelete = (formdata) => {
  return API.post("/seo/deleteseo", formdata);
};

export const getRecentlyAddedExt = (formdata) =>
  API.post("/ext/recentlyAddedExtensions", formdata);

export const getRecentlyUpdatedExt = (formdata) =>
  API.post("/ext/recentlyUpdatedExtensions", formdata);
export const getRecentlyRemovedExt = (formdata) =>
  API.post("/ext/recentlyremovedExtensions", formdata);
export const getExtensionByCategory = (formdata) =>
  API.post("/ext/extbycat", formdata);
export const mostDownloadedExt = (formdata) =>
  API.post("/ext/mostdownloaded", formdata);
export const getMenifestExt = (formdata) => API.post("/ext/manifest", formdata);
