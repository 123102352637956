import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { updateSeo, deleteSeo } from "../../redux/Slices/addSeoSlice";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { deleteSEO, getSEO } from "../../redux/Slices/extSlice";

export default function DataTable({ rows, setUpdate }) {
  console.log(rows, "rows");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notify = (value) =>
    toast.success(value, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const handleblock = () => {};
  const handleunblock = () => {};
  // const handleblock = async (id) => {
  //     const data = {
  //         active: 0,
  //         id,
  //     };
  //     dispatch(deleteSeo(data)).then((response) => {
  //         if (response.payload.code) {
  //             notify(response.payload.message);
  //         }
  //     });
  //     setUpdate(prev => !prev);

  // }

  // const handleunblock = async (id) => {
  //     const data = {
  //         active: 1,
  //         id,
  //     };
  //     dispatch(deleteSeo(data)).then((response) => {
  //         if (response.payload.code) {
  //             notify(response.payload.message);
  //         }
  //     });
  //     setUpdate(prev => !prev);
  // }
  const handleDelete = (id) => {
    console.log(id, "id");

    dispatch(deleteSEO({ id: id }))
      .then((data) => {
        console.log(data);
        dispatch(getSEO());
        notify("Successfully Deleted");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editSeo = async (id, title, desc, page) => {
    console.log(id);
    navigate(`/seo/${id}`, {
      state: {
        title: title,
        desc: desc,
        page: page,
      },
    });
  };

  const columns = [
    { field: "id", headerName: "ID", width: 220 }, // Changed from '_id' to 'id'
    { field: "title", headerName: "Title", width: 130 },
    { field: "desc", headerName: "Description", width: 130 },
    { field: "page", headerName: "Page", width: 130 }, // Added this field
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <div>
            <IconButton>
              <EditIcon
                onClick={() =>
                  editSeo(
                    params.row.id,
                    params.row.title,
                    params.row.desc,
                    params.row.page
                  )
                }
              />
            </IconButton>
            <IconButton>
              <DeleteIcon onClick={() => handleDelete(params.row.id)} />
            </IconButton>
          </div>
        );
        // ... (keep the existing renderCell code)
      },
    },
  ];

  {
    /* <DeleteIcon sx={{ width: "15px", height: "15px" }} /> */
  }

  return (
    <div
      style={{
        paddingTop: "5%",
        display: "flex",
        gap: "20%",
        textAlign: "center",
      }}
    >
      <div
        className="left"
        style={{
          paddingTop: "20px",
          flex: 1,
          marginLeft: "18%",
          overflowX: "scroll",
          marginRight: "15px",
        }}
      >
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={rows}
            disableRowSelectionOnClick
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
          />
        </div>
      </div>
    </div>
  );
}
