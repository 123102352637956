import authSlice from "./Slices/authSlice";
import { configureStore } from "@reduxjs/toolkit";
import extSlice from "./Slices/extSlice";


const store = configureStore({
  reducer: {
    user: authSlice,
    ext: extSlice,
  },
});

export default store;
