import React, { useEffect, useState } from "react";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  allCategories,
  allGames,
  deleteGameDetail,
} from "../../redux/Slices/extSlice";
import Datatable from "../mui-datatable/Datatable";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import Modal from "@mui/material/Modal";
import UpdateGame from "./UpdateGame";
const AllGames = () => {
  const auth = useSelector((state) => state.user.isAuthenticated);
  const dispatch = useDispatch();
  const allext = useSelector((state) => state.ext.table?.data);
  const { categories } = useSelector((state) => state.ext);
  const { user } = useSelector((state) => state.user);

  const [selectedcategory, setselectedcategory] = useState("all");
  const [updateId, setUpdateId] = useState("");
  const [GamesrowData, setGamesrowData] = useState([]);
  const [page, pagechange] = useState(0);
  const [rowsPerPage, rowsPerPageChange] = useState(5);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // console.log("useffect runned");
    const fetchData = async () => {
      try {
        // if (auth) {
        // let data = { limit };
        const data = { cat: selectedcategory };
        dispatch(allGames(data))
          .then((data) => {
            // console.log(data, "data in algames dispatch");
            if (data.payload?.code == 200) {
              toast.success("Data loaded successfully!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
              });
            } else {
              toast.error("Something went wrong", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
              });
            }
          })
          .catch((err) => {
            console.log(err, "err in all ext");
            toast.error("Something went wrong", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
          });
        dispatch(allCategories())
          .then((data) => {
            if (data.payload?.code == 200) {
              // toast.success("Data loaded successfully!", {
              //   position: toast.POSITION.TOP_CENTER,
              //   autoClose: 3000,
              // });
            } else {
              // toast.error("Something went wrong", {
              //   position: toast.POSITION.TOP_CENTER,
              //   autoClose: 3000,
              // });
            }
          })
          .catch((err) => {
            console.log(err, "err in all ext");
            toast.error("Something went wrong", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
          });
        // }
      } catch (error) {
        console.error("Error fetching extensions:", error);
      }
    };

    fetchData();
  }, []);
  const handleOpen = (rowData) => {
    console.log(rowData, "rowData");
    setOpen(true);
    setUpdateId(rowData?.[0]);
    // console.log(allext, "allext");
    let selectedRow = allext.filter((data) => data._id === rowData?.[0]);

    setGamesrowData(selectedRow);
  };
  const handleDelete = async (id) => {
    console.log("runned");
    const formData = {
      id,
    };

    try {
      if (user?.role == "admin" || user?.user?.role == "admin") {
        dispatch(deleteGameDetail(formData)).then((data) => {
          console.log(data, "data");
          if (data?.payload?.code === 200) {
            const data = { cat: selectedcategory };
            dispatch(allGames(data));
            toast.success("Deleted Successfully!", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
          } else {
            toast.error("Something went wrong!", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
          }
        });
      } else {
        toast.error("You are not authorize for this action", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  const handleClose = () => setOpen(false);
  const renderActionCell = (value, tableMeta) => {
    const testimonial_id = tableMeta.rowData[0];

    // console.log(alltest, "alltest");

    return (
      <div>
        <IconButton>
          <EditIcon onClick={() => handleOpen(tableMeta.rowData)} />
        </IconButton>
        <IconButton>
          <DeleteIcon onClick={() => handleDelete(tableMeta.rowData[0])} />
        </IconButton>
      </div>
    );
  };
  // console.log(allext, "allext");

  const columns = allext
    ? [
        ...Object.keys(allext[0]).filter((key) => key !== "cat_arr"),
        {
          name: "actions",
          label: "Actions",
          options: {
            customBodyRender: (value, tableMeta) =>
              renderActionCell(value, tableMeta),
          },
        },
      ]
    : [];
  const options = {
    selectableRows: "none",
    filter: true,
    print: false,
    download: false,
    pagination: true,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [5, 10, 15],
    page: page,
    onChangePage: (newPage) => {
      // console.log(page, "page in onchangepage");
      pagechange(newPage);
      // if (newPage === Math.ceil(allext.length / rowsPerPage) - 1) {
      //   // setLimit((prev) => prev + 15);
      // }

      // pagechange({ ...page, current: page, prev: page - 1 });
    },
    onChangeRowsPerPage: (value) => {
      rowsPerPageChange(value);
      // if (value > allext.length) {
      //   setLimit((prev) => prev + 15);
      // }
      // pagechange(0);
    },
  };

  const setCategory = async (value) => {
    setselectedcategory(value);
    try {
      let data = {
        cat: value,
      };
      dispatch(allGames(data))
        .then((data) => {
          if (data.payload?.code == 200) {
            toast.success("Data loaded successfully!", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
          } else {
            toast.error("Something went wrong", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
          }
        })
        .catch((err) => {
          console.log(err, "err in all ext");
          toast.error("Something went wrong", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        });
    } catch (error) {
      console.log(error, "error in setCategory");
    }
  };

  return (
    <Paper
      sx={{
        width: "80%",
        float: "right",
        overflow: "auto",
        marginTop: "8%",
        marginRight: "23px",
      }}
    >
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small-label">Categories</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={selectedcategory}
          label="Categories"
          onChange={(e) => setCategory(e.target.value)}
        >
          {/* {catOptions.map((cat) => (
            <MenuItem value={cat?.value}>{cat?.label}</MenuItem>
          ))} */}
          <MenuItem value="all">All</MenuItem>
          {categories?.data &&
            categories?.data.length > 0 &&
            categories?.data.map((category) => (
              <MenuItem value={category._id}>{category.cat_name}</MenuItem>
            ))}
        </Select>
      </FormControl>
      <Datatable
        title="Games Details"
        data={allext?.length > 0 ? allext : []}
        columns={columns}
        options={options}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <UpdateGame
          categories={categories?.data}
          handleClose={handleClose}
          updateId={updateId}
          testimonialtoupdate={GamesrowData}
        ></UpdateGame>
      </Modal>
    </Paper>
  );
};

export default AllGames;
