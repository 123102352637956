import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addGame,
  allCategories,
  allGames,
} from "../../../redux/Slices/extSlice";
import { toast } from "react-toastify";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Button,
  TextField,
  Chip,
  OutlinedInput,
} from "@mui/material";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
import draftToHtml from "draftjs-to-html";

import { Box } from "@mui/material";

const AddGame = () => {
  const allext = useSelector((state) => state.ext.table?.data);
  const { categories } = useSelector((state) => state.ext);
  const formRef = useRef(null);
  const editor = useRef(null);

  const dispatch = useDispatch();

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);
  const [fields, setFields] = useState({
    _id: "",
    game_name: "",
    game_file: "",
    meta_title: "",
    meta_description: "",
    cat_arr: [],
    featured_img: "",
    layout: "",
    screenshots: [],
    title: "",
    description: "",
    developer_name: "",
    release_date: "",

    // link:"",
    // path: "",
  });
  useEffect(() => {
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setFields((prevData) => ({
      ...prevData,
      description: html,
    }));
    setConvertedContent(html);
  }, [editorState]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  useEffect(() => {
    //   console.log("useffect runned");
    const fetchData = async () => {
      try {
        // if (auth) {
        // let data = { limit };
        const data = { cat: "all" };

        dispatch(allCategories())
          .then((data) => {
            if (data.payload?.code == 200) {
            } else {
              toast.error("Something went wrong", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
              });
            }
          })
          .catch((err) => {
            console.log(err, "err in all ext");
            toast.error("Something went wrong", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
          });
        // }
      } catch (error) {
        console.error("Error fetching extensions:", error);
      }
    };

    fetchData();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value, "ertyu");
    setFields((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleChangeLayout = (e) => {
    const { value } = e.target;
    setFields((prevFields) => ({
      ...prevFields,
      layout: value,
    }));
    // console.log(fields.layout, "new ew")
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;

    setFields({
      ...fields,
      cat_arr: typeof value === "string" ? value.split(",") : value,
    });

    // Note: This might not show the latest state due to closure
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      console.log(fields, "fields");
      const formData = new FormData();
      // Handle game_file
      if (!fields.game_file || !fields.featured_img) {
        return toast.info("Game file and featured img is required", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
      // Handle regular fields
      Object.keys(fields).forEach((key) => {
        if (
          key !== "screenshots" &&
          key !== "game_file" &&
          key !== "featured_img" &&
          key !== "cat_arr" &&
          key !== "game_name"
        ) {
          formData.append(key, fields[key]);
        }
      });
      formData.append(
        "game_link",
        `https://www.punogames.com/games/${fields.game_name
          .replace(/[^a-z0-9-_]+/gi, "_")
          .toLowerCase()}`
      );
      if (fields.game_name) {
        formData.append("game_name", fields["game_name"]);
      }
      if (Array.isArray(fields.cat_arr)) {
        // Handle cat_arr
        // console.log(fields.cat_arr, "fields.cat_arr");
        formData.append("cat_arr", JSON.stringify(fields.cat_arr));
      }

      // Handle game_file
      if (fields.game_file instanceof File) {
        formData.append("game_file", fields.game_file, fields.game_file.name);
      }

      // Handle featured_img
      if (fields.featured_img instanceof File) {
        formData.append(
          "featured_img",
          fields.featured_img,
          fields.featured_img.name
        );
      }

      // Handle screenshots as an array

      let screenshotsArray = fields.screenshots.map((field) => field);
      console.log(screenshotsArray, "screenshotsArray");
      // formData.append(`screenshots`, screenshotsArray);
      if (Array.isArray(fields.screenshots)) {
        fields.screenshots.forEach((screenshot, index) => {
          console.log(screenshot, "screenshot");
          if (screenshot instanceof File) {
            formData.append(`screenshots`, screenshot);
          }
        });
      }
      // }

      // Log formData contents (for debugging)
      // formData.forEach((value, key) => {
      //   console.log(`${key}:`, value, "key value");
      // });

      // Dispatch action with both formData (for file upload) and jsonData (for other data)
      dispatch(addGame(formData)).then((data) => {
        console.log(data, "data");
        if (data.payload.code === 200) {
          if (formRef.current) {
            formRef.current.reset();
          }
          setFields({
            _id: "",
            game_name: "",
            game_file: "",
            meta_title: "",
            meta_description: "",
            cat_arr: [],
            featured_img: "",
            layout: "",
            screenshots: [],
            title: "",
            description: "",
            developer_name: "",
            release_date: "",
          });
          setConvertedContent("");
          setEditorState(() => EditorState.createEmpty());
          toast.success("Game added successfully!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        } else {
          toast.error("Something went wrong", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      });
    } catch (error) {
      console.log(error, "error");
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };
  const CreateObjectUrl = (img) => {
    const objectURL = URL.createObjectURL(img);
    console.log(objectURL, "objectURL");
    return objectURL;
  };
  //   console.log(allext[0], categories?.data, "allext categories");
  const renderField = (key, value) => {
    console.log(key, "key");
    if (key === "_id") return null;
    // if (key === "meta_title") {
    //   return (
    //     <TextField
    //       key={key}
    //       required
    //       label="Meta Title"
    //       value={fields[key]}
    //       sx={{ width: "100%" }}
    //       name={key}
    //       onChange={handleChange}
    //     />
    //   );
    // }

    if (key === "meta_description") {
      return (
        <TextField
          key={key}
          required
          label="Meta Description"
          value={fields[key]}
          multiline
          rows={4}
          sx={{ width: "100%" }}
          name={key}
          onChange={handleChange}
        />
      );
    }
    if (key === "layout") {
      return (
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Layout</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={fields.layout}
              label="Layout"
              onChange={handleChangeLayout}
            >
              <MenuItem value={"portrait"}>Portrait</MenuItem>
              <MenuItem value={"landscape"}>Landscape</MenuItem>
            </Select>
          </FormControl>
        </Box>
      );
    }
    if (key === "screenshots") {
      return (
        <div
          key={key}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "10px",
          }}
        >
          <Button variant="contained" component="label">
            Upload Screenshots
            <input
              type="file"
              hidden
              multiple
              accept="image/*"
              onChange={(e) => {
                const files = Array.from(e.target.files);
                console.log(files, "files in screenshot");
                setFields((prevFields) => ({
                  ...prevFields,
                  [key]: [...(prevFields[key] || []), ...files],
                }));
              }}
            />
          </Button>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            {fields[key] &&
              fields[key].map((file, index) => (
                <div
                  key={index}
                  style={{
                    width: "100px",
                    height: "100px",
                    position: "relative",
                  }}
                >
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Screenshot ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <Button
                    size="small"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                    }}
                    onClick={() => {
                      setFields((prevFields) => ({
                        ...prevFields,
                        [key]: prevFields[key].filter((_, i) => i !== index),
                      }));
                    }}
                  >
                    X
                  </Button>
                </div>
              ))}
          </div>
        </div>
      );
    }

    if (key === "cat_arr" && Array.isArray(value)) {
      return (
        <FormControl key={key}>
          <InputLabel id="demo-multiple-chip-label">
            {" "}
            {key.replace(/_/g, " ").toUpperCase()}
          </InputLabel>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            required
            value={fields.cat_arr}
            onChange={handleSelectChange}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {categories?.data?.map((category) => (
              <MenuItem
                key={category._id}
                value={category._id}
                data-value={category.cat_name}
              >
                {category.cat_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    if (key.includes("img") || key.includes("file")) {
      return (
        <div
        //   style={{
        //     display: "flex",
        //     flexDirection: "column",
        //     justifyContent: "center",
        //     alignItems: "center",
        //   }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <Button variant="contained" component="label" key={key}>
              Upload {key.replace(/_/g, " ").toUpperCase()}
              <input
                type="file"
                accept={key.includes("file") ? ".zip" : ""}
                hidden
                onChange={(e) => {
                  if (key.includes("file")) {
                    if (
                      e.target.files[0] &&
                      e.target.files[0].type === "application/x-zip-compressed"
                    ) {
                      setFields((prevData) => ({
                        ...prevData,
                        [key]: e.target.files[0],
                      }));
                      console.log(
                        `Zip file uploaded for ${key}:`,
                        e.target.files[0]
                      );
                    }
                  } else {
                    setFields((prevData) => ({
                      ...prevData,
                      [key]: e.target.files[0],
                    }));
                    // Handle file upload logic here
                    console.log(`File uploaded for ${key}:`, e.target.files[0]);
                  }
                }}
              />
            </Button>
            {fields[key] && key.includes("file") && (
              <Chip
                label={fields.game_file.name}
                variant="outlined"
                onDelete={() =>
                  setFields((prevData) => ({
                    ...prevData,
                    [key]: "",
                  }))
                }
              />
            )}
          </div>

          {fields.featured_img && key.includes("img") && (
            <div
              style={{
                width: "100px",
                height: "100px",
                position: "relative",
              }}
            >
              <img
                src={CreateObjectUrl(fields.featured_img)}
                alt={`Screenshot`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              <Button
                size="small"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                }}
                onClick={() => {
                  setFields((prevFields) => ({
                    ...prevFields,
                    [key]: "",
                  }));
                }}
              >
                X
              </Button>
            </div>
          )}
          {}
        </div>
      );
    }
    if (key.includes("release_date")) {
      return (
        <div style={{ marginBottom: "20px" }}>
          <TextField
            key={key}
            required
            // label={key.replace(/_/g, " ").toUpperCase()}
            type="date"
            value={fields[key]}
            defaultValue={new Date().toISOString().split("T")[0]}
            onChange={handleChange}
            sx={{ width: "100%" }}
            name={key}
          />
        </div>
      );
    }
    if (key.includes("description")) {
      console.log("in if condition");
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            maxWidth: "90%",
          }}
        >
          <div style={{ width: "100%" }}>
            <p style={{ paddingLeft: "5px" }}>GAME DESCRIPTION</p>

            {/* <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
            /> */}
            <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
            />
          </div>
          <div style={{ width: "100%" }}>
            <p style={{ paddingLeft: "5px" }}>HTML</p>
            <div style={{ width: "100%", height: "100px" }}>
              <textarea
                style={{ width: "100%", height: "100px" }}
                value={convertedContent}
                placeholder="Type anything…"
              />
            </div>
          </div>
        </div>
      );
    }
    if (key === "game_name") {
      return (
        <>
          <TextField
            key={key}
            required
            label={key.replace(/_/g, " ").toUpperCase()}
            value={fields[key]}
            // multiline={key === "description"}
            sx={{ width: "100%" }}
            name={key}
            onChange={handleChange}
            // maxRows={key === "description" ? 4 : 1}
          />

          {key === "game_name" && (
            <p>
              {" "}
              your game path will be :{" "}
              {fields[key].replace(/[^a-z0-9-_]+/gi, "_").toLowerCase()}
            </p>
          )}
        </>
      );
    }

    return (
      <>
        <TextField
          key={key}
          required
          label={key.replace(/_/g, " ").toUpperCase()}
          value={fields[key]}
          // multiline={key === "description"}
          sx={{ width: "100%" }}
          name={key}
          onChange={handleChange}
          // maxRows={key === "description" ? 4 : 1}
        />
      </>
    );
  };
  return (
    <Paper
      sx={{
        width: "80%",
        float: "right",
        overflow: "auto",
        marginTop: "8%",
        marginRight: "23px",
        padding: "10px",
      }}
    >
      <h2
        sx={{ paddingLeft: "20px", marginBottom: "40px" }}
        style={{ textAlign: "center" }}
      >
        Add Game
      </h2>
      <form onSubmit={handleSubmit} ref={formRef}>
        <div style={{ display: "flex", gap: "20px" }}>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            {Object.entries(fields)
              .slice(0, Math.ceil(Object.entries(fields).length / 2))
              .map(([key, value]) => renderField(key, value))}
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            {Object.entries(fields)
              .slice(Math.ceil(Object.entries(fields).length / 2))
              .filter((data) => data[0] !== "category_names")
              .map(([key, value]) => renderField(key, value))}
          </div>
        </div>

        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          {" "}
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </div>
      </form>
    </Paper>
  );
};

export default AddGame;
