// "use strict";
Object.defineProperty(exports, "__esModule", {
  value: true,
});
if (process.env.NODE_ENV === "development") {
  exports.default = {
    panelURL: "https://localhost:4000/",
    // baseURL: "https://punogames.com/api",
    imageURL: "https://cdn.punogames.com/",
    baseURL: "http://localhost:4000/api",
    backendURL: "http://localhost:8800/api",
  };
} else {
  exports.default = {
    websiteURL: "https://admin.punogames.com/",
    baseURL: "https://admin.punogames.com/api",
    imageURL: "https://cdn.punogames.com/",
    // baseURL: "http://localhost:4000/api",
    backendURL: "https://admin.punogames.com/api",
  };
}
