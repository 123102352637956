import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  allCategories,
  allGames,
  deleteGameDetail,
  setFeaturedPriority,
} from "../../redux/Slices/extSlice";
import { toast } from "react-toastify";
import {
  Paper,
  TextField,
  Button,
  MenuItem,
  FormControl,
  Input,
} from "@mui/material";
const UpdateFeaturedGames = () => {
  const dispatch = useDispatch();
  const catref = useRef();

  const [categories, setCategories] = useState([]);
  const allext = useSelector((state) => state.ext.table?.data);
  useEffect(() => {
    try {
      dispatch(allGames({ cat: "all" }))
        .then((data) => {
          // console.log(data, "data in algames dispatch");
          if (data.payload?.code == 200) {
            toast.success("Data loaded successfully!", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
          } else {
            toast.error("Something went wrong", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
          }
        })
        .catch((err) => {
          console.log(err, "err in all ext");
          toast.error("Something went wrong", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        });
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (allext) {
      setCategories(allext);
    }
  }, [allext]);
  console.log(allext, "test");
  // console.log(categories, "testttttt");
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("handleSubmit");

    console.log({
      gameId: catref.current.value,
    });
    try {
      dispatch(
        setFeaturedPriority({
          gameId: catref.current.value,
        })
      )
        .then((data) => {
          console.log(data, "data in algames dispatch");
          if (data.payload?.code == 200) {
            toast.success(`${data.payload.message}`, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
            catref.current.value = "";
          } else if (data.payload?.code == 201) {
            toast.success(`${data.payload.message}`, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
            catref.current.value = "";
          } else if (data.payload?.code == 204) {
            toast.success(`${data.payload.message}`, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
            catref.current.value = "";
          } else {
            toast.warning("Game already present in featured", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
          }
        })
        .catch((err) => {
          console.log(err, "err in all ext");
          toast.error("Something went wrong", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        });
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };
  return (
    <Paper
      sx={{
        width: "75%",
        float: "right",
        overflow: "auto",
        marginTop: "8%",
        marginRight: "23px",
        padding: "10px",
      }}
    >
      <form
        onSubmit={handleSubmit}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <TextField
            select
            inputRef={catref}
            label="Select Game"
            defaultValue=""
            fullWidth
            variant="outlined"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {categories.map((category) => (
              <MenuItem value={category._id} key={category._id}>
                {category.game_name}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        {/* <TextField
          inputRef={pri}
          style={{ width: "100%" }}
          id="outlined-basic"
          label="Outlined"
          variant="outlined"
          type="number"
        /> */}
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Add Games
        </Button>
      </form>
    </Paper>
  );
};

export default UpdateFeaturedGames;
