import React, { useEffect, useState, useRef } from "react";

import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Grid,
  IconButton,
  Box,
  Typography,
  OutlinedInput,
  Chip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  allGames,
  deleteFilePath,
  updateGameDetail,
  updateGameDetailformData,
} from "../../redux/Slices/extSlice";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  height: "80vh",
  overflow: "scroll",
  scrollbarWidth: "none", // For Firefox
  msOverflowStyle: "none", // For Internet Explorer and Edge
  "&::-webkit-scrollbar": {
    display: "none", // For Chrome, Safari, and Opera
  },
};
const UpdateGame = ({
  handleClose,
  updateId,
  testimonialtoupdate,
  categories,
}) => {
  const dispatch = useDispatch();
  const nameRef = useRef(null);
  const designationRef = useRef(null);
  const reviewRef = useRef(null);
  // const statusRef = useRef('1');
  const [status, setStatus] = useState("1");
  const [file, setFile] = useState(null);
  const [FormDatas, setFormData] = useState(testimonialtoupdate[0]);
  const [editorState, setEditorState] = useState(() => {
    const blocksFromHtml = htmlToDraft(testimonialtoupdate[0].description);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    return EditorState.createWithContent(contentState);
  });
  const [convertedContent, setConvertedContent] = useState(null);

  // const _contentState = ContentState.createFromText(
  //   testimonialtoupdate[0].description
  // );
  // const raw = convertToRaw(_contentState); // RawDraftContentState JSON
  // const [contentState, setContentState] = useState(raw); // ContentState JSON
  // const [convertedState, setconvertedState] = useState(); // ContentState JSON

  // console.log(contentState, "contentState");
  useEffect(() => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setFormData((prevData) => ({
      ...prevData,
      description: html,
    }));
    setConvertedContent(html);
  }, [editorState]);
  // const [FormDatas, setFormData] = useState(testimonialtoupdate[0]);
  // const path4 = "http://localhost:8800/featured_img/KUKU.jpg";
  console.log(FormDatas, "FormDatas");
  const handleEditorChange = (state) => {
    setEditorState(state);
  };
  const handleChangeStatus = (e) => {
    setStatus(e.target.value); // Update the status state
  };
  const CreateObjectUrl = (img) => {
    const objectURL = URL.createObjectURL(img);
    console.log(objectURL, "objectURL");
    return objectURL;
  };
  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...FormDatas,
      cat_arr: typeof value === "string" ? value.split(",") : value,
    });
    //  console.log("Updated fields:", fields); // Note: This might not show the latest state due to closure
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // const formData = new FormDatas();
    console.log(FormDatas, "FormDatas");

    let useFormData = false;

    const formData = new FormData();
    // if (typeof FormDatas.game_file !== "string") {
    //   // formData.append(
    //   //   "game_file",
    //   //   FormDatas.game_file,
    //   //   FormDatas.game_file.name
    //   // );
    //   useFormData = true;
    // }
    if (typeof FormDatas.featured_img !== "string") {
      // console.log(FormDatas.featured_img);
      // formData.append(
      //   "featured_img",
      //   FormDatas.featured_img,
      //   FormDatas.featured_img.name
      // );
      useFormData = true;
    }

    if (Array.isArray(FormDatas.cat_arr)) {
      formData.append("cat_arr", JSON.stringify(FormDatas.cat_arr));
    }

    if (useFormData) {
      console.log("in if useFormData", useFormData);

      Object.keys(FormDatas).forEach((key) => {
        if (
          key !== "game_file" &&
          key !== "featured_img" &&
          key !== "cat_arr"
        ) {
          formData.append(key, FormDatas[key]);
        }
      });

      if (typeof FormDatas.featured_img !== "string") {
        console.log(FormDatas.featured_img);
        formData.append(
          "featured_img",
          FormDatas.featured_img,
          FormDatas.featured_img.name
        );
        useFormData = true;
      }

      // if (typeof FormDatas.game_file !== "string") {
      //   formData.append(
      //     "game_file",
      //     FormDatas.game_file,
      //     FormDatas.game_file.name
      //   );
      // }

      dispatch(updateGameDetailformData(formData)).then((data) => {
        const datatosend = { cat: "all" };
        dispatch(allGames(datatosend));
        handleClose();
        toast.success("Updated Successfully!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
    } else {
      // console.log("in else useFormData", useFormData);
      dispatch(updateGameDetail(FormDatas)).then((data) => {
        const datatosend = { cat: "all" };
        dispatch(allGames(datatosend));
        handleClose();
        toast.success("Updated Successfully!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
    }
  };

  const handleGamesChange = (e) => {
    setFormData({ ...FormDatas, [e.target.name]: e.target.value });
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <div>
      <Box sx={style}>
        <Button color="error" onClick={handleClose} sx={{ marginLeft: "90%" }}>
          <CancelOutlinedIcon />
        </Button>
        <Typography
          variant="h6"
          style={{ paddingBottom: "20px", textAlign: "center" }}
        >
          Updating Game No. {updateId}
        </Typography>

        <form onSubmit={handleSubmit}>
          <div style={{ display: "flex", gap: "20px" }}>
            <div
              style={{
                flex: "3",
                display: "flex",
                gap: "12px",
                justifyContent: "center",
                alignItems: "start",
              }}
            >
              <div style={{ flex: "1" }}>
                {!testimonialtoupdate[0]?.featured_img && (
                  <div
                  //   style={{
                  //     display: "flex",
                  //     flexDirection: "column",
                  //     justifyContent: "center",
                  //     alignItems: "center",
                  //   }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <Button
                        variant="contained"
                        component="label"
                        key={"key"}
                        disabled={FormDatas.featured_img ? true : false}
                      >
                        Upload {"Featured Image"}
                        <input
                          type="file"
                          accept="image/png, image/gif, image/jpeg"
                          hidden
                          onChange={(e) => {
                            setFormData((prevData) => ({
                              ...prevData,
                              ["featured_img"]: e.target.files[0],
                            }));
                            // Handle file upload logic here
                          }}
                        />
                      </Button>
                      {/* {key.includes("file") && FormDatas[key] && (
                              <Chip
                                label={
                                  FormDatas?.game_file?.name?.split("/")[1] ||
                                  FormDatas?.game_file?.name ||
                                  FormDatas?.game_file?.split("/")[5]
                                }
                                variant="outlined"
                                onDelete={async () => {
                                  let data = {
                                    pathtodelete: FormDatas[key],
                                    field: "game_file",
                                    documentId: FormDatas._id,
                                  };
                                  if (typeof FormDatas.game_file == "string") {
                                    if (FormDatas?.game_file?.split("/")[5])
                                      dispatch(deleteFilePath(data)).then(
                                        (data) => {
                                          if (data.payload?.code === 200) {
                                            setFormData((prevData) => ({
                                              ...prevData,
                                              [key]: "",
                                            }));
                                          } else {
                                            toast.error(
                                              data.payload?.message ||
                                                "Something went wrong",
                                              {
                                                position:
                                                  toast.POSITION.TOP_CENTER,
                                                autoClose: 3000,
                                              }
                                            );
                                          }
                                        }
                                      );
                                  } else {
                                    setFormData((prevData) => ({
                                      ...prevData,
                                      [key]: "",
                                    }));
                                  }
                                }}
                              />
                            )} */}
                    </div>

                    {FormDatas["featured_img"] && (
                      <div
                        style={{
                          // marginBlock: "20px",
                          width: "100px",
                          height: "100px",
                          position: "relative",
                          marginBottom: "20px",
                        }}
                      >
                        <img
                          // style={{ width: "100px", height: "100px" }}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          src={CreateObjectUrl(FormDatas.featured_img)}
                          alt="feature_image"
                        />

                        {/* <img
                                src={FormDatas.featured_img}
                                alt={`Screenshot`}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              /> */}

                        <Button
                          size="small"
                          style={{
                            position: "absolute",

                            top: 0,
                            right: 0,
                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                          }}
                          onClick={() => {
                            setFormData((prevData) => ({
                              ...prevData,
                              ["featured_img"]: "",
                            }));
                          }}
                        >
                          X
                        </Button>
                      </div>
                    )}
                  </div>
                )}
                {Object.keys(testimonialtoupdate[0])
                  .slice(0, 6)
                  .filter(
                    (data) => data !== "game_link" || data !== "game_name"
                  )
                  .map((key) => {
                    console.log(key, "all keys");
                    if (
                      key === "_id" ||
                      key === "game_name" ||
                      key === "game_link"
                    )
                      return null;

                    if (key === "description")
                      return (
                        <div
                          key={key}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <TextField
                            label={key.replace(/_/g, " ").toUpperCase()}
                            defaultValue={testimonialtoupdate[0][key]}
                            // inputRef={inputRefs[key]}
                            multiline
                            sx={{ width: "100%" }}
                            name={key}
                            onChange={handleGamesChange}
                            maxRows={4}
                          /> */}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "20px",
                              maxWidth: "90%",
                            }}
                          >
                            <div style={{ width: "100%" }}>
                              <p style={{ paddingLeft: "5px" }}>
                                GAME DESCRIPTION
                              </p>

                              {/* <Editor
                                editorState={editorState}
                                onEditorStateChange={handleEditorChange}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                              /> */}
                              <Editor
                                editorState={editorState}
                                onEditorStateChange={setEditorState}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                              />
                            </div>
                            <div style={{ width: "100%" }}>
                              <p style={{ paddingLeft: "5px" }}>HTML</p>
                              <div style={{ width: "100%", height: "100px" }}>
                                <textarea
                                  style={{ width: "100%", height: "100px" }}
                                  value={convertedContent}
                                  placeholder="Type anything…"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );

                    if (key === "layout") {
                      return (
                        <Box sx={{ minWidth: 120, marginBottom: "20px" }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Layout
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="layout"
                              value={FormDatas.layout}
                              label="Layout"
                              onChange={handleGamesChange}
                              //  onChange={handleChangeLayout}
                            >
                              <MenuItem value={"portrait"}>Portrait</MenuItem>
                              <MenuItem value={"landscape"}>Landscape</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      );
                    }

                    return (
                      <div
                        key={key}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "20px",
                        }}
                      >
                        <TextField
                          label={key.replace(/_/g, " ").toUpperCase()}
                          defaultValue={testimonialtoupdate[0][key]}
                          // inputRef={inputRefs[key]}
                          sx={{ width: "100%" }}
                          name={key}
                          onChange={handleGamesChange}
                        />
                      </div>
                    );
                  })}
              </div>
              <div style={{ flex: "1" }}>
                {Object.keys(testimonialtoupdate[0])
                  .slice(6, Object.keys(testimonialtoupdate[0]).length)
                  .filter(
                    (data) =>
                      data !== "cat_arr" ||
                      data !== "game_link" ||
                      data !== "game_name"
                  )
                  .map((key) => {
                    console.log(key, "all keys  in second fase");
                    if (
                      key === "_id" ||
                      key === "screenshots" ||
                      key === "game_file" ||
                      key === "game_link" ||
                      key === "cat_arr" ||
                      key === "game_name"
                    )
                      return null;
                    if (key.includes("img")) {
                      return (
                        <div
                        //   style={{
                        //     display: "flex",
                        //     flexDirection: "column",
                        //     justifyContent: "center",
                        //     alignItems: "center",
                        //   }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "20px",
                              marginBottom: "20px",
                            }}
                          >
                            <Button
                              variant="contained"
                              component="label"
                              key={key}
                              disabled={
                                key.includes("img") && FormDatas.featured_img
                                  ? true
                                  : false
                              }
                            >
                              Upload {key.replace(/_/g, " ").toUpperCase()}
                              <input
                                type="file"
                                accept={key.includes("file") ? ".zip" : ""}
                                hidden
                                disabled={
                                  key.includes("img") && FormDatas.featured_img
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  if (key.includes("file")) {
                                    if (
                                      e.target.files[0] &&
                                      e.target.files[0].type ===
                                        "application/x-zip-compressed"
                                    ) {
                                      setFormData((prevData) => ({
                                        ...prevData,
                                        [key]: e.target.files[0],
                                      }));
                                      console.log(
                                        `Zip file uploaded for ${key}:`,
                                        e.target.files[0]
                                      );
                                    }
                                  } else {
                                    setFormData((prevData) => ({
                                      ...prevData,
                                      [key]: e.target.files[0],
                                    }));
                                    // Handle file upload logic here
                                    console.log(
                                      `File uploaded for ${key}:`,
                                      e.target.files[0]
                                    );
                                  }
                                }}
                              />
                            </Button>
                            {/* {key.includes("file") && FormDatas[key] && (
                              <Chip
                                label={
                                  FormDatas?.game_file?.name?.split("/")[1] ||
                                  FormDatas?.game_file?.name ||
                                  FormDatas?.game_file?.split("/")[5]
                                }
                                variant="outlined"
                                onDelete={async () => {
                                  let data = {
                                    pathtodelete: FormDatas[key],
                                    field: "game_file",
                                    documentId: FormDatas._id,
                                  };
                                  if (typeof FormDatas.game_file == "string") {
                                    if (FormDatas?.game_file?.split("/")[5])
                                      dispatch(deleteFilePath(data)).then(
                                        (data) => {
                                          if (data.payload?.code === 200) {
                                            setFormData((prevData) => ({
                                              ...prevData,
                                              [key]: "",
                                            }));
                                          } else {
                                            toast.error(
                                              data.payload?.message ||
                                                "Something went wrong",
                                              {
                                                position:
                                                  toast.POSITION.TOP_CENTER,
                                                autoClose: 3000,
                                              }
                                            );
                                          }
                                        }
                                      );
                                  } else {
                                    setFormData((prevData) => ({
                                      ...prevData,
                                      [key]: "",
                                    }));
                                  }
                                }}
                              />
                            )} */}
                          </div>

                          {key.includes("img") && FormDatas[key] && (
                            <div
                              style={{
                                // marginBlock: "20px",
                                width: "100px",
                                height: "100px",
                                position: "relative",
                              }}
                            >
                              <img
                                // style={{ width: "100px", height: "100px" }}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                                src={
                                  typeof FormDatas.featured_img !== "string"
                                    ? CreateObjectUrl(FormDatas.featured_img)
                                    : `https://www.punogames.com/assets/${FormDatas[key]}`
                                }
                                alt="feature_image"
                              />

                              {/* <img
                                src={FormDatas.featured_img}
                                alt={`Screenshot`}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              /> */}

                              <Button
                                size="small"
                                style={{
                                  position: "absolute",

                                  top: 0,
                                  right: 0,
                                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                                }}
                                onClick={() => {
                                  let data = {
                                    pathtodelete: `${process.env.REACT_APP_ASSETS_PATH}${FormDatas[key]}`,
                                    field: "featured_img",
                                    documentId: FormDatas._id,
                                  };
                                  if (
                                    typeof FormDatas.featured_img == "string"
                                  ) {
                                    dispatch(deleteFilePath(data)).then(
                                      (data) => {
                                        if (data.payload?.code === 200) {
                                          setFormData((prevData) => ({
                                            ...prevData,
                                            [key]: "",
                                          }));
                                        } else {
                                          toast.error(
                                            data.payload?.message ||
                                              "Something went wrong",
                                            {
                                              position:
                                                toast.POSITION.TOP_CENTER,
                                              autoClose: 3000,
                                            }
                                          );
                                        }
                                      }
                                    );
                                  } else {
                                    setFormData((prevData) => ({
                                      ...prevData,
                                      [key]: "",
                                    }));
                                  }
                                }}
                              >
                                X
                              </Button>
                            </div>
                          )}
                        </div>
                      );
                    }

                    if (key === "description")
                      return (
                        <div
                          key={key}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "20px",
                          }}
                        >
                          <TextField
                            label={key.replace(/_/g, " ").toUpperCase()}
                            defaultValue={testimonialtoupdate[0][key]}
                            // inputRef={inputRefs[key]}
                            multiline
                            sx={{ width: "100%" }}
                            name={key}
                            onChange={handleGamesChange}
                            maxRows={4}
                          />
                        </div>
                      );

                    if (key === "category_names") {
                      console.log(key, "lskdjflsdjf");
                      return (
                        <FormControl key={key} style={{ marginBlock: "20px" }}>
                          <InputLabel id="demo-multiple-chip-label">
                            {" "}
                            {"CATEGORIES"}
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            // value={testimonialtoupdate[0][key]}
                            value={FormDatas.cat_arr}
                            onChange={handleSelectChange}
                            input={
                              <OutlinedInput
                                id="select-multiple-chip"
                                label="Chip"
                              />
                            }
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value) => (
                                  <Chip
                                    key={value}
                                    label={value}
                                    // onDelete={(e) => {
                                    //   e.stopPropogation();
                                    //   alert(value);
                                    // }}
                                  />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {categories?.map((category) => {
                              return (
                                <MenuItem
                                  key={category._id}
                                  value={category._id}
                                >
                                  {category.cat_name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      );
                    }

                    return (
                      <div
                        key={key}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "20px",
                        }}
                      >
                        <TextField
                          label={key.replace(/_/g, " ").toUpperCase()}
                          defaultValue={testimonialtoupdate[0][key]}
                          // inputRef={inputRefs[key]}
                          sx={{ width: "100%" }}
                          name={key}
                          onChange={handleGamesChange}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default UpdateGame;

// {
//   <div
//     key={index}
//     style={{
//       display: "flex",
//       alignItems: "center",
//       marginBottom: "10px",
//     }}
//   >
//     <TextField
//       label={`Screenshot ${index + 1}`}
//       value={screenshot}
//       onChange={(e) => handleScreenshotChange(index, e.target.value)}
//       sx={{ width: "90%" }}
//     />
//     <IconButton
//       onClick={() => handleDeleteScreenshot(index)}
//       aria-label="delete"
//     >
//       <DeleteIcon />
//     </IconButton>
//   </div>;
// }
