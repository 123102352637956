import axios from "axios";
import AppConstatnt from "../../constants/AppConstant";


const API = axios.create({ baseURL: `${AppConstatnt.baseURL}` });
// const verifyAPI = axios.create({
//   baseURL: `${baseURL}`,
//   headers: {
//     Authorization: `Bearer ${data + "1234"}`,
//   },
//   withCredentials: true,
// });

export const signup = (formData) => API.post("/user/signup", formData);
export const loginWithPassword = (formData) =>
  API.post("/user/login", formData);
export const getProfiler = (data) =>
  API.post(
    "/user/verifylogin",
    {}, // If there's no data to be sent in the body
    {
      headers: {
        Authorization: `Bearer ${data}`,
      },
      // withCredentials: true,
    }
  );
export const logoutsess = () => API.get("/authpanel/logout");
