import * as React from "react";
import { useEffect, useState } from "react";
import {
  // InputLabel,
  // Paper,
  useTheme,
  TextField,
  Button,
  // Grid,
  // Box,
  // Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
// import Button from "@mui/material/Button";
// import DeleteIcon from "@mui/icons-material/Delete";
// import SendIcon from "@mui/icons-material/Send";
// import Stack from "@mui/material/Stack";
// import { getSeoDetails, createSeo } from "../../redux/Slices/addSeoSlice";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addSEO } from "../../redux/Slices/extSlice";

export default function AddDetails({ setUpdate }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [personName, setPersonName] = React.useState([]);
  const [title, setTitle] = useState(null);
  const [description, setDesc] = useState(null);
  const [data, setData] = useState(null);

  const notify = (value) =>
    toast.success(value, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const failure = (value) =>
    toast.error(value, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  function ComboBox({ onChange }) {
    return (
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={pageList}
        value={data}
        onChange={(event, newValue) => {
          // Check if newValue is not null before accessing 'label'
          onChange(newValue ? newValue.label : null);
        }}
        sx={{ width: "100%" }}
        renderInput={(params) => <TextField {...params} label="Select" />}
      />
    );
  }

  // Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
  const pageList = [
    { label: "/" },
    { label: "/about-us" },
    { label: "/contact-us" },
    { label: "/privacy-policy" },
    { label: "/terms-conditions" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    const seodata = { title, desc: description, page: data };
    console.log(seodata, "seodata");
    dispatch(addSEO(seodata))
      .then((response) => {
        setTitle(" ");
        setDesc(" ");
        setData(" ");
        console.log(response, "response");
        if (response.payload.code == 200) {
          notify(response.payload.message);
        }
        if (response.payload.code == 400) {
          failure(response.payload.message);
        }
      })
      .catch((error) => {
        console.log(error);
        failure(error);
      });
    // dispatch(createSeo(seodata))
    //   .then((response) => {
    //     setTitle(" ");
    //     setDesc(" ");
    //     setData(" ");
    //     console.log(response, "response");
    //     if (response.payload.code == 201) {
    //       notify(response.payload.message);
    //     }
    //     if (response.payload.code == 400) {
    //       failure(response.payload.message);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     failure(error);
    //   });
    setUpdate((prev) => !prev);
  };

  return (
    <div style={{ marginTop: "30px" }}>
      <div
        style={{
          paddingTop: "5%",
          display: "flex",
          gap: "20%",
          textAlign: "center",
        }}
      >
        <h1 style={{ paddingLeft: "18%" }}>Add Data</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex",
            gap: "20%",
            marginLeft: "10px",
            paddingLeft: "18%",
            justifyContent: "space-around",
            marginBottom: "30px",
          }}
        >
          <div style={{ width: "100%" }}>Seo Title</div>
          <div style={{ width: "100%", marginRight: "15px" }}>
            <TextField
              value={title}
              fullWidth
              id="fullWidth"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
        </div>

        {/* <div style={{ display: "flex", gap: "20%", textAlign: "center" }}>
          <div
            className="left"
            style={{
              paddingTop: "20px",
              marginLeft: "10px",
              flex: 1,
              marginLeft: "10%",
            }}
          >
            Seo Title
          </div>
          <div className="right" style={{ flex: 1, marginRight: "2%" }}>
            <TextField
              value={title}
              fullWidth
              id="fullWidth"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
        </div> */}

        <div
          style={{
            display: "flex",
            gap: "20%",
            marginLeft: "10px",
            paddingLeft: "18%",
            justifyContent: "space-around",
            marginBottom: "30px",
          }}
        >
          <div style={{ width: "100%" }}>Seo Desc</div>
          <div style={{ width: "100%", marginRight: "15px" }}>
            <TextField
              value={description}
              fullWidth
              id="fullWidth"
              onChange={(e) => {
                setDesc(e.target.value);
              }}
            />
          </div>
        </div>

        {/* <div
          style={{
            paddingTop: "10px",
            display: "flex",
            gap: "20%",
            textAlign: "center",
          }}
        >
          <div
            className="left"
            style={{
              paddingTop: "20px",
              marginLeft: "10px",
              flex: 1,
              marginLeft: "10%",
            }}
          >
            Seo Desc
          </div>
          <div className="right" style={{ flex: 1, marginRight: "2%" }}>
            <TextField
              value={description}
              fullWidth
              id="fullWidth"
              onChange={(e) => {
                setDesc(e.target.value);
              }}
            />
          </div>
        </div> */}

        <div
          style={{
            display: "flex",
            gap: "20%",
            marginLeft: "10px",
            paddingLeft: "18%",
            justifyContent: "space-around",
            marginBottom: "30px",
          }}
        >
          <div style={{ width: "100%" }}>Page Name</div>
          <div style={{ width: "100%", marginRight: "15px" }}>
            <ComboBox onChange={setData} />
          </div>
        </div>

        {/* <div
          style={{
            marginLeft: "3px",
            display: "flex",
            gap: "20%",
            textAlign: "center",
          }}
        >
          <div
            className="left"
            style={{
              paddingTop: "20px",
              marginLeft: "10px",
              flex: 1,
              marginLeft: "10%",
            }}
          >
            Seo Data
          </div>
          <div
            className="right"
            style={{ flex: 1, marginRight: "2%", paddingTop: "10px" }}
          >
            
            <ComboBox onChange={setData} />
          </div>
        </div> */}

        <div style={{ marginLeft: "50%", marginTop: "10px" }}>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}
