import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux/es/hooks/useSelector";
const PrivateRoutes = () => {
  // console.log("PrivaterouteRendered")
  const userState = useSelector((state) => state.user);

  const authenticated = userState.isAuthenticated;

  return (
    <div>{authenticated ? <Outlet /> : <Navigate to="/login" replace />}</div>
  );
};

export default PrivateRoutes;
