import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { addCategory } from "../../redux/Slices/extSlice";
import { toast } from "react-toastify";
import {
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Input,
} from "@mui/material";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

const AddCategory = () => {
  const dispatch = useDispatch();
  const catRef = useRef();
  const logoRef = useRef();
  const titleRef = useRef();
  const descRef = useRef();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const handleEditorChange = (state) => {
    setEditorState(state);
    console.log("Editor State", state);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(editorState, "editorState");
    const descriptionHtml = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    console.log("Converted HTML:", descriptionHtml);
    const formData = new FormData();
    formData.append("cat_name", catRef.current.value);
    formData.append("cat_logo", logoRef.current.files[0]);
    formData.append("cat_description", descriptionHtml);
    formData.append("meta_title", titleRef.current.value);
    formData.append("meta_description", descRef.current.value);

    dispatch(addCategory(formData))
      .then((data) => {
        if (data.payload?.code === 200) {
          // console.log("if");
          // formRef.current.reset();
          catRef.current.value = "";
          logoRef.current.value = "";
          setEditorState(EditorState.createEmpty());
          titleRef.current.value = "";
          descRef.current.value = "";

          toast.success("Data loaded successfully!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        } else {
          toast.error("Something went wrong", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err, "err in all ext");
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
    // console.log("Category Name:", catRef.current.value);
    // console.log("Logo File:", logoRef.current.files[0]);
  };

  return (
    <Paper
      sx={{
        width: "75%",
        float: "right",
        overflow: "auto",
        marginTop: "8%",
        marginRight: "23px",
        padding: "10px",
      }}
    >
      <Typography variant="h5" component="h3" gutterBottom>
        Add A Game Category
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ width: "100%", mt: 1 }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="category"
          label="Category Name"
          name="category"
          autoFocus
          inputRef={catRef}
        />
        <Box sx={{ mt: 2, mb: 2, width: "100%" }}>
          <Typography gutterBottom>Description</Typography>
          <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={handleEditorChange}
            placeholder="Type here..."
          />
          <textarea
            disabled
            value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
            style={{ width: "100%", marginTop: "18px" }}
          />
        </Box>

        <TextField
          margin="normal"
          required
          fullWidth
          id="metaTite"
          label="Meta Title"
          name="metaTitle"
          autoFocus
          inputRef={titleRef}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          id="metaDescription"
          label="Meta Description"
          name="metaDescription"
          autoFocus
          inputRef={descRef}
          multiline
          rows={4}
        />
        <Input
          type="file"
          name="Upload Icon"
          required
          fullWidth
          inputRef={logoRef}
          sx={{ mt: 2, mb: 2 }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Add Category
        </Button>
      </Box>
    </Paper>
  );
};

export default AddCategory;
