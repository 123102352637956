import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";
const Profile = () => {
  // const { email, name, role } = useSelector((state) => state.user.user);
  // console.log(email, name, role, "email, name, role");
  const { user } = useSelector((state) => state.user);
  console.log(user, "user");
  return (
    <div style={{ paddingTop: "1px", marginLeft: "15%" }}>
      <Paper
        elevation={10}
        sx={{
          borderRadius: "15px",
          width: "50%",
          height: "70%",
          marginLeft: "20%",
          marginTop: "10%",
          padding: "40px",
        }}
      >
        <Avatar
          alt="User Avatar"
          src=""
          sx={{
            width: 150,
            height: 150,
            margin: "0 auto",
          }}
        />

        <Typography variant="h5" align="center" gutterBottom>
          {user?.user?.name || user?.name}
        </Typography>

        <Typography variant="subtitle1" align="center">
          Role: {user?.user?.role || user?.role}
        </Typography>

        <Typography variant="subtitle1" align="center">
          Email: {user?.user?.email || user?.email}
        </Typography>
      </Paper>
    </div>
  );
};

export default Profile;
