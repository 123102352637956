import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getAllExt,
  getRecentlyAddedExt,
  getRecentlyRemovedExt,
  getRecentlyUpdatedExt,
  getExtensionByCategory,
  mostDownloadedExt,
  getMenifestExt,
  getAllGames,
  getAllCategories,
  updategamedetail,
  deletegamedetail,
  addcategory,
  getAllCategory,
  deleteCategory,
  addgame,
  getfeaturedGames,
  setfeaturedpriority,
  deletefilepath,
  updategamedetailformdata,
  addSeo,
  Seoadd,
  Seoget,
  Seoupdate,
  Seodelete,
} from "../api/extapi";

export const allGames = createAsyncThunk("games/all", async (data) => {
  try {
    const response = await getAllGames(data);
    return response.data;
  } catch (error) {
    console.log(error.message, "errorrrrr");
    throw error; // You should rethrow the error to handle it in the component.
  }
});
export const addSEO = createAsyncThunk("seo/add", async (data) => {
  try {
    const response = await Seoadd(data);
    return response.data;
  } catch (error) {
    console.log(error.message, "errorrrrr");
    throw error; // You should rethrow the error to handle it in the component.
  }
});
export const updateSEO = createAsyncThunk("seo/update", async (data) => {
  try {
    const response = await Seoupdate(data);
    return response.data;
  } catch (error) {
    console.log(error.message, "errorrrrr");
    throw error; // You should rethrow the error to handle it in the component.
  }
});
export const getSEO = createAsyncThunk("seo/get", async (data) => {
  try {
    const response = await Seoget(data);
    return response.data;
  } catch (error) {
    console.log(error.message, "errorrrrr");
    throw error; // You should rethrow the error to handle it in the component.
  }
});
export const deleteSEO = createAsyncThunk("seo/delete", async (data) => {
  try {
    const response = await Seodelete(data);
    return response.data;
  } catch (error) {
    console.log(error.message, "errorrrrr");
    throw error; // You should rethrow the error to handle it in the component.
  }
});
export const getallcategory = createAsyncThunk("category/all", async () => {
  try {
    const response = await getAllCategory();
    return response.data;
  } catch (error) {
    console.log(error.message, "errorrrrr");
    throw error;
  }
});

export const getFeaturedGames = createAsyncThunk("featured/all", async () => {
  try {
    const response = await getfeaturedGames();
    return response.data;
  } catch (error) {
    console.log(error.message, "errorrrrr");
    throw error;
  }
});
export const setFeaturedPriority = createAsyncThunk(
  "featured/priority",
  async (formdata) => {
    try {
      const response = await setfeaturedpriority(formdata);
      return response.data;
    } catch (error) {
      console.log(error.message, "errorrrrr");
      throw error;
    }
  }
);
export const deletecategory = createAsyncThunk(
  "category/delete",
  async (formdata) => {
    console.log(formdata, "slice");
    try {
      const response = await deleteCategory(formdata);
      return response.data;
    } catch (error) {
      console.log(error.message, "errorrrrr");
      throw error;
    }
  }
);
export const updateGameDetail = createAsyncThunk(
  "/games/update",
  async (formdata) => {
    try {
      const response = await updategamedetail(formdata);

      return response.data;
    } catch (error) {
      console.log(error, "err");
      throw error;
    }
  }
);
export const updateGameDetailformData = createAsyncThunk(
  "/games/updateform",
  async (formdata) => {
    try {
      const response = await updategamedetailformdata(formdata);

      return response.data;
    } catch (error) {
      console.log(error, "err");
      throw error;
    }
  }
);

export const addCategory = createAsyncThunk(
  "/category/addcategory",
  async (formdata, { rejectWithValue }) => {
    try {
      const response = await addcategory(formdata);

      return response?.data;
    } catch (error) {
      console.error("Error in addCategory:", error);
      return rejectWithValue(error);
    }
  }
);
export const addGame = createAsyncThunk(
  "/games/add",
  async (formdata, { rejectWithValue }) => {
    try {
      const response = await addgame(formdata);

      return response?.data;
    } catch (error) {
      console.error("Error in addCategory:", error);
      return rejectWithValue(error);
    }
  }
);

export const deleteGameDetail = createAsyncThunk(
  "/games/delete",
  async (formdata, { rejectWithValue }) => {
    try {
      console.log(formdata, "slice");
      const response = await deletegamedetail(formdata);

      return response.data;
    } catch (error) {
      console.log(error, "err");

      return rejectWithValue(error);
    }
  }
);
export const deleteFilePath = createAsyncThunk(
  "/games/deletepath",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data, "slice");
      const response = await deletefilepath(data);

      return response.data;
    } catch (error) {
      console.log(error, "err");

      return rejectWithValue(error);
    }
  }
);
export const allCategories = createAsyncThunk(
  "categories/all",
  async (data) => {
    try {
      const response = await getAllCategories(data);

      return response.data;
    } catch (error) {
      console.log(error.message, "errorrrrr");
      throw error; // You should rethrow the error to handle it in the component.
    }
  }
);

const extSlice = createSlice({
  name: "extSlice",
  initialState: {
    table: null,
    categories: null,
    message: null,
    tableAddedExt: null,
    tableUpdatedExt: null,
    tableRemovedExt: null,
    tableExtbyCate: null,
    tableMostDownloadedExt: null,
    tablemanifestExt: null,
    allcategory: null,
    featuredgames: null,
    prioritymessage: null,
    seodata: null,
  },
  extraReducers: (builder) => {
    builder.addCase(allGames.fulfilled, (state, action) => {
      state.table = action.payload;
    });
    builder.addCase(allGames.rejected, (state, action) => {
      state.table = null;
    });
    builder.addCase(addSEO.fulfilled, (state, action) => {
      state.message = action.payload;
    });
    builder.addCase(addSEO.rejected, (state, action) => {
      state.table = null;
    });
    builder.addCase(getSEO.fulfilled, (state, action) => {
      state.seodata = action.payload;
    });
    builder.addCase(getSEO.rejected, (state, action) => {
      state.seodata = null;
    });
    builder.addCase(deleteSEO.fulfilled, (state, action) => {
      state.message = action.payload;
    });
    builder.addCase(deleteSEO.rejected, (state, action) => {
      state.message = null;
    });
    builder.addCase(updateSEO.fulfilled, (state, action) => {
      state.message = action.payload;
    });
    builder.addCase(updateSEO.rejected, (state, action) => {
      state.prioritymessage = null;
    });
    builder.addCase(addCategory.fulfilled, (state, action) => {
      // console.log(action.payload, "action");
      state.message = "Category Added";
    });
    builder.addCase(addCategory.rejected, (state, action) => {
      state.message = null;
    });
    builder.addCase(addGame.fulfilled, (state, action) => {
      console.log(action.payload, "action");
    });
    builder.addCase(addGame.rejected, (state, action) => {
      state.message = null;
    });
    builder.addCase(updateGameDetail.fulfilled, (state, action) => {
      state.table = action.payload;
    });
    builder.addCase(updateGameDetail.rejected, (state, action) => {
      state.table = null;
    });
    builder.addCase(updateGameDetailformData.fulfilled, (state, action) => {
      state.table = action.payload;
    });
    builder.addCase(updateGameDetailformData.rejected, (state, action) => {
      state.table = null;
    });
    builder.addCase(getFeaturedGames.fulfilled, (state, action) => {
      state.featuredgames = action.payload;
    });
    builder.addCase(getFeaturedGames.rejected, (state, action) => {
      state.featuredgames = null;
    });
    builder.addCase(setFeaturedPriority.fulfilled, (state, action) => {
      state.prioritymessage = action.payload;
    });
    builder.addCase(setFeaturedPriority.rejected, (state, action) => {
      state.prioritymessage = null;
    });
    builder.addCase(getallcategory.fulfilled, (state, action) => {
      state.allcategory = action.payload;
    });
    builder.addCase(getallcategory.rejected, (state, action) => {
      state.allcategory = null;
    });
    builder.addCase(deleteGameDetail.fulfilled, (state, action) => {
      state.table = action.payload;
    });
    builder.addCase(deleteGameDetail.rejected, (state, action) => {
      state.table = null;
    });
    builder.addCase(deleteFilePath.fulfilled, (state, action) => {
      state.message = action.payload;
    });
    builder.addCase(deleteFilePath.rejected, (state, action) => {
      state.message = null;
    });
    builder.addCase(allCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
    });
    builder.addCase(allCategories.rejected, (state, action) => {
      state.categories = null;
    });
  },
});

export default extSlice.reducer;
