import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFeaturedGames,
  setFeaturedPriority,
} from "../../redux/Slices/extSlice";
import Datatable from "../mui-datatable/Datatable";
// import { Paper, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Paper,
  Button,
  Modal,
  Box,
  TextField,
  Typography,
} from "@mui/material";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const FeaturedGames = () => {
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [editingGame, setEditingGame] = useState(null);
  const [newPriority, setNewPriority] = useState("");
  const [currgame, setCurrgame] = useState("");
  const featuredgames = useSelector((state) => state.ext?.featuredgames?.data);
  const handleEdit = (game) => {
    console.log(game, "game");
    setEditingGame(game[1]);
    setCurrgame(game[2]);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditingGame(null);
    setNewPriority("");
  };

  const handleSaveEdit = () => {
    console.log(
      "Saving new priority for game:",
      editingGame,
      "New priority:",
      newPriority,
      currgame
    );
    try {
      dispatch(
        setFeaturedPriority({
          gameId: editingGame,
          priority: +newPriority,
        })
      )
        .then((data) => {
          // console.log(data, "data in algames dispatch");
          if (data.payload?.code == 200) {
            toast.success(`${data.payload.message}`, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
            dispatch(getFeaturedGames());
          } else if (data.payload?.code == 201) {
            toast.success(`${data.payload.message}`, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
          } else {
            toast.error("Something went wrong", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
          }
        })
        .catch((err) => {
          console.log(err, "err in all ext");
          toast.error("Something went wrong", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        });
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
    handleCloseModal();
  };
  useEffect(() => {
    dispatch(getFeaturedGames());
  }, [dispatch]);

  const columns = [
    ...(featuredgames && featuredgames.length > 0
      ? Object.keys(featuredgames[0]).map((key) => ({
          name: key,
          label: key.charAt(0).toUpperCase() + key.slice(1),
          options: {
            filter: true,
            sort: true,
          },
        }))
      : []),
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const game = tableMeta.rowData; // Assuming the ID is in the first column
          return (
            <>
              <Button
                onClick={() => handleEdit(game)}
                startIcon={<EditIcon />}
                size="small"
              >
                Edit Priority
              </Button>
            </>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    filter: true,
    print: false,
    download: false,
    pagination: true,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [5, 10, 15],
    page: page,
    onChangePage: (newPage) => {
      setPage(newPage);
    },
    onChangeRowsPerPage: (newRowsPerPage) => {
      setRowsPerPage(newRowsPerPage);
      setPage(0);
    },
  };

  return (
    <div>
      <Paper
        sx={{
          width: "80%",
          float: "right",
          overflow: "auto",
          marginTop: "8%",
          marginRight: "23px",
        }}
      >
        {featuredgames && (
          <Datatable
            title="Featured Games"
            data={featuredgames || []}
            columns={columns}
            options={options}
          />
        )}
      </Paper>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Edit Game Priority
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h6">
            {currgame}
          </Typography>
          <TextField
            fullWidth
            label="Priority"
            type="number"
            value={newPriority}
            onChange={(e) => setNewPriority(e.target.value)}
            margin="normal"
          />
          <Button onClick={handleSaveEdit} variant="contained" sx={{ mt: 2 }}>
            Save Changes
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default FeaturedGames;
