import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  login,
  logout,
  setAdminRole,
  loginAsync,
} from "../../redux/Slices/authSlice";
import { toast } from "react-toastify";
import image from "../../image/gameHub.png";
import {
  Box,
  Paper,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { NavLink, useNavigate } from "react-router-dom";
import { setCookie } from "../../utils/cookiehelper";

function Login() {
  const [logindata, setLoginData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  // const isAuthenticated = useSelector((state) => state.user);
  const role = useSelector((state) => state.user.role);
  const statedata = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await dispatch(loginAsync(logindata));
      console.log(response, "login response");
      if (response.payload.code == 400) {
        toast.error(
          response?.payload?.message
            ? response?.payload?.message
            : "Invalid Credentials",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          }
        );
      }
      if (response && response.payload?.data?.user) {
        setCookie("token", response.payload?.data?.token, 160, "Strict");
        dispatch(login(response.payload?.data?.user));
        dispatch(setAdminRole(response.payload?.data?.user?.role));
        // Move the navigate call inside the try block
        navigate("/");
      }
    } catch (error) {
      console.log(error);

      toast.error("Invalid Credentials", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
    setLoading(false);
  };

  const handlechange = (key) => (event) => {
    setLoginData({ ...logindata, [key]: event.target.value });
  };
  console.log(statedata, "after login");
  // console.log(isAuthenticated, "login");
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Paper elevation={10} sx={{ borderRadius: "15px" }}>
        <form onSubmit={handleLogin}>
          <Stack
            direction="column"
            justifyContent="space-around"
            alignItems="center"
            spacing={3}
            px={10}
            py={5}
          >
            <Box variant="h4" align="center">
              <img
                style={{
                  display: "block",
                  objectFit: "cover",
                  height: "auto",
                  width: "150px",
                  marginLeft: "30px",
                }}
                src={image}
                alt="random"
              />
            </Box>
            <Typography variant="h4" align="center">
              Login
            </Typography>
            <Stack spacing={2}>
              <FormControl>
                <TextField
                  label="Email"
                  required
                  size="small"
                  value={logindata.email}
                  onChange={handlechange("email")}
                />
                <FormHelperText>Enter your email</FormHelperText>
              </FormControl>

              <FormControl>
                <TextField
                  label="Password"
                  required
                  size="small"
                  type={showPassword ? "text" : "password"}
                  value={logindata.password}
                  onChange={handlechange("password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle-password-visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText>Enter your password</FormHelperText>
              </FormControl>

              <Typography variant="body2">
                Don't have an account?{" "}
                <NavLink to="/signup">
                  <strong>Signup</strong>
                </NavLink>
              </Typography>
            </Stack>
            <Stack direction="row">
              <LoadingButton
                variant="contained"
                fullWidth
                type="submit"
                loading={loading}
              >
                Login
              </LoadingButton>
            </Stack>
          </Stack>
        </form>
      </Paper>
    </Box>
  );
}

export default Login;
