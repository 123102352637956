/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import image from "../../image/gameHub.png";
import { registerAsync } from "../../redux/Slices/authSlice";

import {
  Box,
  Paper,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { NavLink, useNavigate } from "react-router-dom";
import { signup } from "../../redux/api/authapi";
import { toast } from "react-toastify";

function Signup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [signupData, setSignupData] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
  });

  const [validationMessages, setValidationMessages] = useState({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [passfiled, setpassfiled] = useState(false);

  const [number, setnumber] = useState(false);
  const [special, setspecial] = useState(false);
  const [uppercase, setuppercase] = useState(false);
  const [lengths, setlengths] = useState(false);
  const [passwordmatch, setpasswordmatch] = useState(true);

  const validateEmail = (email) => {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const validPasswordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*_])[A-Za-z\d!@#$%^&*_]{8,}$/;
    return validPasswordRegex.test(password); // Placeholder, replace with actual validation
  };

  const handleChange = (key) => (event) => {
    event.preventDefault();

    setSignupData({ ...signupData, [key]: event.target.value });

    if (key === "password") {
      // console.log("password filed selected");
      setpassfiled(true);

      const isvalidatepassword = validatePassword(event.target.value);
      // console.log(isvalidatepassword, "isvalidatepassword");
      if (isvalidatepassword) {
        setpassfiled(false);
      }

      const capitalRegex = /[A-Z]/;
      const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-=|]/;
      const numberRegex = /[0-9]/;
      const lengthRegex = event.target.value.length;
      // console.log(lengthRegex, "lengthRegex");

      capitalRegex.test(event.target.value)
        ? setuppercase(true)
        : setuppercase(false);
      specialCharRegex.test(event.target.value)
        ? setspecial(true)
        : setspecial(false);
      numberRegex.test(event.target.value) ? setnumber(true) : setnumber(false);
      lengthRegex >= 8 ? setlengths(true) : setlengths(false);
      const passwordValidationResults = validatePassword(event.target.value);
      console.log(passwordValidationResults.number);
      setValidationMessages((prev) => ({
        ...prev,
        password: passwordValidationResults
          ? ""
          : "Password Criteria is not matched",
      }));
    } else if (key === "email") {
      const isValidEmail = validateEmail(event.target.value);
      setValidationMessages((prev) => ({
        ...prev,
        email: isValidEmail ? "" : "Email is not valid",
      }));
    }
  };
  // console.log(validationMessages, "validationMessages");
  const userRolesData = ["admin", "user"];
  // toggle password show
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  ///on submit button action-----------------------
  const handlesignup = async (e) => {
    e.preventDefault();

    const { name, email, password, role, confirmPassword } = signupData;
    if (!name || !email || !password || !role || !confirmPassword) {
      return toast.error("Please fill all the fields", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
    const isValidEmail = validateEmail(email);
    if (!isValidEmail) {
      return toast.error("Email is not valid", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
    const isValidPassword = validatePassword(password);
    // console.log(isValidPassword, "isvalid");
    if (!isValidPassword) {
      return toast.error("Password Criteria is not matched", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
    let passwordMatched = false;
    passwordMatched = password == confirmPassword;
    if (!passwordMatched) {
      return toast.error("Password and Confirm Password does not match", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
    // const passwordismatch = signupData.password === signupData.ConfrimPassword;
    // passwordismatch ? setpasswordmatch(true) : setpasswordmatch(false);

    const signupdata = {
      name,
      email,
      password,
      role,
    };
    // console.log(isValidEmail, "isValidEmail");
    // console.log(isValidPassword, "isValidPassword");
    // console.log("first");
    try {
      if (isValidEmail && isValidPassword) {
        dispatch(registerAsync(signupdata)).then((data) => {
          if (data?.payload?.code == 200) {
            e.target.reset();
            setTimeout(() => {
              navigate("/login");
            }, 3000);
          }
        });
      } else {
        return toast.error("Something went wrong!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Axios error:", error);
      return toast.error("Something went wrong", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Paper elevation={10} sx={{ borderRadius: "15px", overflow: "hidden" }}>
        <form onSubmit={handlesignup}>
          <Stack
            direction="column"
            justifyContent="space-around"
            alignItems="center"
            spacing={3}
            px={10}
            py={5}
          >
            <Box variant="h4" align="center">
              <img
                style={{
                  display: "block",
                  objectFit: "cover",
                  height: "auto",
                  width: "150px",
                  marginLeft: "30px",
                }}
                src={image}
                alt="random"
              />
            </Box>
            <Typography variant="h4" align="center">
              Signup
            </Typography>

            <Stack spacing={2}>
              <TextField
                label="Name"
                required
                size="small"
                value={signupData.name}
                onChange={handleChange("name")}
              />

              <TextField
                label="Email"
                required
                size="small"
                value={signupData.email}
                onChange={handleChange("email")}
              />

              <TextField
                label="Password"
                required
                size="small"
                type={showPassword ? "text" : "password"}
                value={signupData.password}
                onChange={handleChange("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle-password-visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {passfiled ? (
                <div className="validpasswword">
                  <div>
                    {/* {special ? ( */}
                    <li style={{ color: special ? "green" : "red" }}>
                      One special character
                    </li>
                    {/* // ) : (
                    //   <li className="true_condition">One special character</li>
                    // )} */}
                    {/* {uppercase ? ( */}
                    <li style={{ color: uppercase ? "green" : "red" }}>
                      One uppercase character
                    </li>
                    {/* ) : (
                      <li className="true_condition">
                        One uppercase character
                      </li>
                    )} */}
                  </div>
                  <div>
                    {/* {lengths ? ( */}
                    <li style={{ color: lengths ? "green" : "red" }}>
                      8 characters minimum
                    </li>
                    {/* ) : (
                      <li className="true_condition">8 characters minimum</li>
                    )} */}

                    {/* {number ? ( */}
                    <li
                      style={{ color: number ? "green" : "red" }}
                      className="uppercasee"
                    >
                      One number
                    </li>
                    {/* ) : (
                      <li className="uppercasee true_condition">One number</li>
                    )} */}
                  </div>
                </div>
              ) : (
                ""
              )}

              <TextField
                label="Confirm Password"
                required
                size="small"
                type={showConfirmPassword ? "text" : "password"}
                value={signupData.confirmPassword}
                onChange={handleChange("confirmPassword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle-password-visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText="Must be same as password"
              />

              <TextField
                label="Select Role"
                select
                value={signupData.role}
                onChange={handleChange("role")}
                size="small"
                helperText="Select your role assigned by organization."
                SelectProps={{ displayEmpty: true }}
              >
                {userRolesData.map((item, i) => {
                  return (
                    <MenuItem key={i} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Stack>

            <Typography variant="body2">
              Already have an account?{" "}
              <NavLink to="/login">
                <strong>Login</strong>
              </NavLink>
            </Typography>

            <Stack direction="row">
              <LoadingButton variant="contained" fullWidth type="submit">
                Signup
              </LoadingButton>
            </Stack>
          </Stack>
        </form>
      </Paper>
    </Box>
  );
}

export default Signup;
